<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasFocus: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>

<template>
    <div :class="{ hasFocus: hasFocus }">
        <BaseLabel :field="field" />
        <input
            :id="field.name"
            v-model="field.value"
            class="input"
            :name="field.name"
            type="date"
            :placeholder="field.placeholder"
            :required="field.required"
            v-on="listeners"
            @focus="hasFocus = true"
            @blur="hasFocus = false"
        />
    </div>
</template>
